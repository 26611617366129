/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import {useSelector} from 'react-redux';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import { checkAccess } from '../../../../../app/userAccess';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };
  
  const user = useSelector(({ auth }) => {
    return auth.user;
  })

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-arrange.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

        {
          checkAccess("Draws.view") && <li
              className={`menu-item ${getMenuItemActive("/draws", false)}`}
              aria-haspopup="true"
          >
              <NavLink className="menu-link" to="/draws">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Duplicate.svg")}/>
              </span>
                <span className="menu-text">Draws</span>
              </NavLink>
          </li>
        }

        {checkAccess("manageOrganisationAdmin.view") && <li className={`menu-item ${getMenuItemActive(
                  "/users"
              )}`}
              aria-haspopup="true"
        >
            <NavLink className="menu-link" to="/users">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>
              </span>
              <span className="menu-text">Users</span>
            </NavLink>
        </li>}
        
          {user.userType != 0 && <li className={`menu-item ${getMenuItemActive(
                  "/users-group"
              )}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/users-group">
              <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")}/>
              </span>
              <span className="menu-text">Users Group</span>
            </NavLink>
          </li>}
          
        {checkAccess("Tickets.view") && <li className={`menu-item ${getMenuItemActive(
                  "/tickets"
              )}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/tickets">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")}/>
              </span>
              <span className="menu-text">Tickets</span>
            </NavLink>
          </li>}
          {user.userType == 0 && <li className={`menu-item ${getMenuItemActive(
                  "/organization"
              )}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/organization">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
              </span>
              <span className="menu-text">Organization</span>
            </NavLink>
          </li>}
          {
          user.userType == 0 && <li
              className={`menu-item ${getMenuItemActive("/organization/draws", false)}`}
              aria-haspopup="true"
          >
              <NavLink className="menu-link" to="/organization/draws">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Duplicate.svg")}/>
              </span>
                <span className="menu-text">Draws</span>
              </NavLink>
          </li>
        }
        {
          user.userType == 0 && <li
              className={`menu-item ${getMenuItemActive("/organization/tickets", false)}`}
              aria-haspopup="true"
          >
              <NavLink className="menu-link" to="/organization/tickets">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Ticket.svg")}/>
              </span>
                <span className="menu-text">Tickets</span>
              </NavLink>
          </li>
        }

          {/*end::1 Level*/}
          
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}

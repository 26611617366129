import axios from "axios";

export const LOGIN_URL = "organisation/auth/login";
export const REGISTER_URL = "organisation/register";
export const REQUEST_PASSWORD_URL = "organisation/auth/forgotPassword";
export const RESET_PASSWORD_URL = "organisation/auth/resetPassword";

export const ME_URL = "organisation/user/profile";

export function login(userName, password) {
  return axios.post(LOGIN_URL, { userName, password });
}

export function register(data) {
  return axios.post(REGISTER_URL, data);
}
 
export function requestPassword(username) {
  return axios.get(REQUEST_PASSWORD_URL+`?username=${username}`);
}

export function resetPassword(data) {
  return axios.post(RESET_PASSWORD_URL, data);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}


export function requestOtpToUser(type) {
  return axios.get(`organisation/auth/sendOTP/${type}`);
}


export function verifyOTP(otp) {
  return axios.get(`organisation/auth/verifyOTP/?otp=${otp}`);
}

export function paymentIntent() {
  return axios.post('organisation/payment');
}

export function onBoarding() {
  return axios.get('organisation/account/create');
}
import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {drawsSlice} from "../app/modules/Draw/_redux/draws/drawsSlice";
import {usersSlice} from "../app/modules/User/_redux/users/usersSlice";
import {userProfileSlice} from "../app/modules/UserProfile/_redux/profile/profileSlice";
import {usersGroupSlice} from "../app/modules/UsersGroup/_redux/users/usersSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  draws: drawsSlice.reducer,
  users: usersSlice.reducer,
  usersGroup: usersGroupSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  profile: userProfileSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}

import React, { useState } from "react";
import { useFormik } from "formik";
import { Alert } from 'react-bootstrap';
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
// import 'react-phone-number-input/style.css';
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import validator from 'validator';

const initialValues = {
  organisationName: "",
  organisationABN: "",
  nickName: "",
  website: "",
  contactFirstName: "",
  contactLastName: "",
  contactEmail: "",
  contactMobile: "",
  password: "",
  // countryCode: "",
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const RegistrationSchema = Yup.object().shape({
    organisationName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),

    organisationABN: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .notRequired()
    ,
    nickName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    website: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .notRequired(),

    contactFirstName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    contactLastName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),

    contactEmail: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    contactMobile: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // countryCode: Yup.string()
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    stripeId: Yup.string()


  });


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      enableLoading();
      if (!validator.isMobilePhone(values.contactMobile)) {
        setFieldError('contactMobile', "Please enter valid number")
        disableLoading();
        setSubmitting(false)
        return false;
      }
      register(values)
        // .then(({ data: { accessToken } }) => {
        .then((response) => {
          setSubmitting(false)
          if (response.data.codes == 200) {
            setAlert({
              variant: "success",
              message: response.data.message
            });
            if (window.location.href.includes('organization')) {
              setTimeout(() => {
                props.history.push("/organization");
              }, 5000);
            }
            else {
              setTimeout(() => {
                props.history.push("/auth/login");
              }, 5000);
            }

          } else {
            setSubmitting(false);
            setAlert({
              variant: "danger",
              message: response.data.message
            });
          }
          // props.register(response.data.data.accessToken);
          disableLoading();
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}

        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}


        <div className="form-group fv-plugins-icon-container">
          <label>Organisation Name</label>
          <input
            placeholder="Organisation Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "organisationName"
            )}`}
            name="organisationName"
            {...formik.getFieldProps("organisationName")}
          />
          {formik.touched.organisationName && formik.errors.organisationName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.organisationName}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label>Organisation ABN (optional)</label>
          <input
            placeholder="Organisation ABN"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "organisationABN"
            )}`}
            name="organisationABN"
            {...formik.getFieldProps("organisationABN")}
          />
          {formik.touched.organisationABN && formik.errors.organisationABN ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.organisationABN}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label>Nick Name</label>
          <input
            placeholder="Nick Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "nickName"
            )}`}
            name="nickName"
            {...formik.getFieldProps("nickName")}
          />
          {formik.touched.nickName && formik.errors.nickName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.nickName}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label>Website (optional)</label>
          <input
            placeholder="Website"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "website"
            )}`}
            name="website"
            {...formik.getFieldProps("website")}
          />
          {formik.touched.website && formik.errors.website ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.website}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label>Contact First Name</label>
          <input
            placeholder="Contact FirstName"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "contactFirstName"
            )}`}
            name="contactFirstName"
            {...formik.getFieldProps("contactFirstName")}
          />
          {formik.touched.contactFirstName && formik.errors.contactFirstName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.contactFirstName}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label>Contact Last Name</label>
          <input
            placeholder="Contact LastName"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "contactLastName"
            )}`}
            name="contactLastName"
            {...formik.getFieldProps("contactLastName")}
          />
          {formik.touched.contactLastName && formik.errors.contactLastName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.contactLastName}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label>Contact Email</label>
          <input
            placeholder="Contact Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "contactEmail"
            )}`}
            name="contactEmail"
            {...formik.getFieldProps("contactEmail")}
          />
          {formik.touched.contactEmail && formik.errors.contactEmail ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.contactEmail}</div>
            </div>
          ) : null}
        </div>

        {/* <div className="form-group fv-plugins-icon-container">
          <label>Contact Mobile</label>
          <input
            placeholder="Contact Mobile"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "contactMobile"
            )}`}
            name="contactMobile"
            {...formik.getFieldProps("contactMobile")}
          />
          {formik.touched.contactMobile && formik.errors.contactMobile ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.contactMobile}</div>
            </div>
          ) : null}
        </div> */}
        <div className="form-group fv-plugins-icon-container">
          <label>Contact Mobile</label>
          <PhoneInput
            containerClass="country-code-input"
            inputClass="form-control-solid h-auto py-5  w-100"
            country={formik.values.contactMobile}
            value={formik.values.contactMobile}
            onChange={(value, data, event, formattedValue) => {
              formik.setFieldValue('contactMobile', value)
            }}

          />
          {formik.touched.contactMobile && formik.errors.contactMobile ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.contactMobile}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label>Password</label>
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        {/* <div className="form-group fv-plugins-icon-container">
        <label>Country Code</label>
          <PhoneInput
            containerClass="country-code-input"
            inputClass="form-control-solid h-auto py-5  w-100"
            country={formik.values.countryCode}
            value={formik.values.countryCode}
            onChange={phone => formik.setFieldValue('countryCode',phone)}
          />
          {formik.touched.countryCode && formik.errors.countryCode ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.countryCode}</div>
            </div>
          ) : null}
        </div> */}
        <div className="form-group fv-plugins-icon-container">
          <label>Stripe Account Id (optional)</label>
          <input
            placeholder="Stripe Id"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "stripeId"
            )}`}
            name="stripeId"
            {...formik.getFieldProps("stripeId")}
          />
          {formik.touched.stripeId && formik.errors.stripeId ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.stripeId}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
        {alert != null && <Alert variant={alert.variant} onClose={() => setAlert(null)} dismissible className="mt-2">
          {alert.message}
        </Alert>}
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));

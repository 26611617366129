
export const checkAccess = (access) => {
    let userAccess = JSON.parse(window.localStorage.getItem('user_access'));
    
    if(userAccess) {
        userAccess = userAccess.access;
        return userAccess.includes(access);
    }
    return false;
}


import {createSlice} from "@reduxjs/toolkit";

const initialDrawsState = {
  listLoading: false,
  actionsLoading: false,
  profile: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialDrawsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    groupFetched(state, action) {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.groupPages = data;
    },
    setProfile(state, action) {
        state.actionsLoading = false;
        state.profile = action.payload.profile;
        state.error = null;
    },
    // // getDrawById
    // drawFetched: (state, action) => {
    //   state.actionsLoading = false;
    //   state.drawForEdit = action.payload.drawForEdit;
    //   state.error = null;
    // },
    // // findDraws
    // drawsFetched: (state, action) => {
    //   const { total, data } = action.payload;
    //   state.listLoading = false;
    //   state.error = null;
    //   state.entities = data;
    //   state.totalCount = total;
    // },
    // // createDraw
    // drawCreated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   state.entities.push(action.payload.draw);
    // },
    // // updateDraw
    // drawUpdated: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.map(entity => {
    //     console.log('state entity', entity);
    //     if (entity.id === action.payload.draw.id) {
    //       return action.payload.draw;
    //     }
    //     return entity;
    //   });
    // },
    // // deleteDraw
    // drawDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(el => el.id !== action.payload.id);
    // },
    // // deleteDraws
    // drawsDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(
    //     el => !action.payload.ids.includes(el.id)
    //   );
    // },
    // // drawsUpdateState
    // drawsStatusUpdated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   const { ids, status } = action.payload;
    //   state.entities = state.entities.map(entity => {
    //     if (ids.findIndex(id => id === entity.id) > -1) {
    //       entity.status = status;
    //     }
    //     return entity;
    //   });
    // }
  }
});

import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

const DrawPage = lazy(() =>
  import("./modules/Draw/pages/DrawPage")
);


const OrgDrawPage = lazy(() =>
  import("./modules/OrganizationDraw/pages/DrawPage")
);

const UserPage = lazy(() =>
  import("./modules/User/pages/UserPage")
);
const TicketPage = lazy(() =>
  import("./modules/Ticket/pages/TicketPage")
);
const OrganizationPage = lazy(() =>
  import("./modules/Organization/pages/TicketPage")
);
const OrganizationTicket = lazy(() =>
  import("./modules/OrganizationTicket/pages/TicketPage")
);
const UserGroupPage = lazy(() =>
  import("./modules/UsersGroup/pages/UserGroupPage")
);

const UserProfilePage = lazy(() =>
  import("./modules/UserProfile/pages/UserProfilePage")
);

const PaymentPage = lazy(() =>
  import("./modules/Auth/pages/PaymentPage")
);
const AccountReturn = lazy(() =>
  import("./modules/Auth/pages/AccountReturn")
);
const AccountRefresh = lazy(() =>
  import("./modules/Auth/pages/AccountRefresh")
);
const OrganisationRegistration = lazy(() =>
  import("./modules/Auth/pages/Registration")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const paymentModal = useSelector(({ auth }) => {
    return auth.paymentModal;
  });

  return (
    <div>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <ContentRoute path="/builder" component={BuilderPage} />
          <ContentRoute path="/my-page" component={MyPage} />
          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/e-commerce" component={ECommercePage} />
          <Route path="/draws" component={DrawPage} />
          <Route path="/users" component={UserPage} />
          <Route path="/users-group" component={UserGroupPage} />
          <Route path="/user-profile" component={UserProfilePage} />
          {/*<Route path="/payment" component={PaymentPage} />*/}
          <Route path="/tickets" component={TicketPage} />
          <Route path="/organization/tickets" component={OrganizationTicket} />
          <Route path="/organization/draws" component={OrgDrawPage} />
          <Route path="/organization/register" component={OrganisationRegistration} />
          <Route path="/organization" component={OrganizationPage} />

          <Route path="/account/return" component={AccountReturn} />
          <Route path="/account/refresh" component={AccountRefresh} />
          <Redirect to="error/error-v1" />

        </Switch>




      </Suspense>
    </div>
  );
}

import React, { useState } from "react";
import { useFormik } from "formik";
import {Alert} from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { Link, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword, resetPassword } from "../_redux/authCrud";
import * as toastActions from '../../../../redux/toast/toastActions';

const initialValues = {
  password: "",
  confirmpassword: ""
};

function ResetPassword(props) {
  const { intl } = props;
  const params = useParams();
  const location = useLocation();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const history = useHistory();
  const dispatch = useDispatch();
  const [isRequested, setIsRequested] = useState(false);
  const [alert, setAlert] = useState(null);
  const ForgotPasswordSchema = Yup.object().shape({
    // token: Yup.string()
    //   .required("Token is required"),
    password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Password is required"),
    confirmpassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "New password not match"
        )
    }).required("Please enter confirm  password."),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
        console.log('values',values);
        delete values.confirmpassword;
        let data = {
          ...values,
          token: token
        }
        resetPassword(data)
        .then((res) =>{
            if(res.data.codes == 200 ) {
              dispatch(toastActions.showToast({type: "success", message: res.data.data}))
                setIsRequested(true);    
            }
            // eslint-disable-next-line 
            else {
                setAlert({
                    variant: "danger",
                    message: res.data.message
                })
            }

        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
         
        });
    },
  });
  
  if(token == null || token == "") {
    history.push('/auth');
  }
  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* <div className="form-group fv-plugins-icon-container">
                <label>Token</label>
                <input
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "token"
                    )}`}
                    name="token"
                    {...formik.getFieldProps("token")}
                />
                {formik.touched.token && formik.errors.token ? (
                    <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.token}</div>
                    </div>
                ) : null}
            </div> */}
            <div className="form-group fv-plugins-icon-container">
                <label>New Password</label>
                <input
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
                <label>Confirm Password</label>
                <input
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "confirmpassword"
                    )}`}
                    name="confirmpassword"
                    {...formik.getFieldProps("confirmpassword")}
                />
                {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                    <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.confirmpassword}</div>
                    </div>
                ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
            {alert != null && <Alert variant={alert.variant} onClose={() => setAlert(null)} dismissible className="mt-2">
                {alert.message}
            </Alert>}
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));

import React from "react";
import { useSelector} from 'react-redux'
import {Button, Modal} from "react-bootstrap";
import { onBoarding } from '../../../app/modules/Auth/_redux/authCrud';
import { Link } from 'react-router-dom';
import { actions } from '../../../app/modules/Auth/_redux/authRedux';
import  {useDispatch} from 'react-redux';
import {paymentIntent} from '../../../app/modules/Auth/_redux/authCrud';
import PaymentPage from '../../../app/modules/Auth/pages/PaymentPage';
import {
    MixedWidget1,
    MixedWidget14,
    ListsWidget9,
    StatsWidget11,
    StatsWidget12,
    ListsWidget1,
    AdvanceTablesWidget2,
    AdvanceTablesWidget4,
    ListsWidget3,
    ListsWidget4,
    ListsWidget8
} from "../widgets";
export function Demo1Dashboard() {
    const dispatch = useDispatch();

    const user = useSelector(({ auth }) => {
        return auth.user;
    })
    const paymentModal = useSelector(({auth}) => {
        return auth.paymentModal;
    });
      
    const handleOnBoarding = () => {
        onBoarding().then(res => {
          if(res.data.codes == 200)
            window.location.href = res.data.data.url;
        })
    }
    
    const handlePayment = () => {
        paymentIntent().then(res => { 
            dispatch(actions.showPayment(res.data.data));
        })
        
    }
    
    return (<>
            <div className="row">
                {user  && user.isPaymentCompleted != undefined && !user.isPaymentCompleted && 
                    <div className="col-12">
                        <div className="col bg-light-warning px-6 py-4 rounded-sm mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="m-0">Please complete the one time registration fee to activate all the features. </h5>
                                {user && !user.isPaymentCompleted && <Button variant="primary" className="btn btn-warning btn-sm ml-3 float-right" onClick={handlePayment}>Payment</Button>}
                            </div>
                        </div>
                    </div>
                
                }
                {user && user.isRegistered != undefined  && !user.isRegistered &&
                    <div className="col-12">
                    
                        <div className="col bg-light-warning px-6 py-4 rounded-sm mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="m-0">Please complete on-boarding process to start receiving payments. </h5>
                                {user && !user.isRegistered && <Button className="ml-3 btn-warning" onClick={ handleOnBoarding } size="sm" variant="primary">On Boarding</Button>}
                            </div>
                        </div>
                    </div>
                }
                <div className="col-lg-6 col-xxl-4">
                    <MixedWidget1 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4">
                    <ListsWidget9 className="card-stretch gutter-b"/>
                </div>

                <div className="col-lg-6 col-xxl-4">
                    <StatsWidget11 className="card-stretch card-stretch-half gutter-b"/>
                    <StatsWidget12 className="card-stretch card-stretch-half gutter-b"/>
                </div>

                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
                    <ListsWidget1 className="card-stretch gutter-b"/>
                </div>
                <div className="col-xxl-8 order-2 order-xxl-1">
                    <AdvanceTablesWidget2 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget3 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget4 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget8 className="card-stretch gutter-b"/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <MixedWidget14 className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-8">
                    <AdvanceTablesWidget4 className="card-stretch gutter-b" />
                </div>
            </div>
            <Modal
                show={paymentModal != undefined && paymentModal != null && paymentModal.show}
                onHide={() => {dispatch(actions.hidePayment())}}
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>Payment Info</Modal.Header>
                <Modal.Body className="overlay overlay-block qr-code cursor-default">
                  <PaymentPage />
                </Modal.Body>
              </Modal>

    </>);
}

import React from 'react';
import { toastSlice } from "./toastSlice";
import { toast } from "react-toastify";

const { actions } = toastSlice;

const options = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

export const showToast = ({type, message}) => dispatch => {
  switch(type) {
    case 'success':
      toast.success(message, options);
      break;
    case 'error': 
      toast.error(message, options);
      break;
    case 'info': 
      toast.info(message, options);
      break;
    case 'warning': 
      toast.warn(message, options);
      break;
  }
}

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();
      // config.baseURL = "https://api.raffleon.club/api/v1/";
      config.baseURL = "https://api.clublotto.apptechasia.app/api/v1/";
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}

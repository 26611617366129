import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { verifyOTP, requestOtpToUser } from "../_redux/authCrud";

const initialValues = {
  otp: "",
};

function TwoFA(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    otp: Yup.string()
      // .min(6, "Minimum 6 symbols")
      .max(6, "Maximum 6 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(() => {
        verifyOTP(values.otp)
          .then((response) => {
            props.successLogin(response.data.data.token);
          })
          .catch(() => {
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_OTP",
              })
            );
          });
      }, 1000);
    },
  });

  function sendOtpToUser(type) {

    setSendOtp()
    requestOtpToUser(type).then(() =>
      setSendOtp(true))
  }


  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Two-factor authentication</h3>
            <div className="text-muted font-weight-bold">
              Enter OTP you have received in email or phone no.
            </div>
          </div>

          {!sendOtp &&
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                onClick={() => sendOtpToUser('mobile')}
              >
                Send OTP Mobile
              </button>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                onClick={() => sendOtpToUser('email')}

              >
                Send OTP Email
              </button>
            </div>
          }


          {sendOtp &&
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}
              <div className="form-group fv-plugins-icon-container">
                <input
                  type="text"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "otp"
                  )}`}
                  name="otp"
                  {...formik.getFieldProps("otp")}
                />
                {formik.touched.otp && formik.errors.otp ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.otp}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  disabled={formik.isSubmitting}
                >
                  Submit
              </button>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancel
                </button>
                </Link>
              </div>
            </form>
          }
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(TwoFA));

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Stripe from './Stripe';
import { paymentIntent } from '../_redux/authCrud';
import { useSelector } from 'react-redux';

const PaymentPage = props => {

    
    const paymentSecret = useSelector(({auth})=> {
        return auth.paymentModal.paymentIntent
    });

    
    return (
        <div className="card gutter-b w-100">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <Stripe paymentSecret={ paymentSecret}/>
                    </div>
                </div>
            </div>

        </div>
    );
};



export default PaymentPage;